import React from 'react'
import "./src/assets/styles/styles.scss"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const onInitialClientRender = () => {
 window.history.scrollRestoration = 'manual';
}; 
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Lf_c_wpAAAAAFIm-0rdAZnU0O-XCZDgMkq1HEmg">
            {element}
        </GoogleReCaptchaProvider>
    )
}
